export function resolveStatusRequisicaoClass(name) {
    switch (name) {
        case 'Orçamento':
            return 'warning';
        case 'Pendente':
            return 'pendente';
        case 'Aprovada':
            return 'success';
        case 'Reprovada':
            return 'danger';
        case 'Enviado':
            return 'info';
        case 'Executado':
            return 'muted';
        default: //encerrada
            return 'dark';
    }
}

export function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

export function toHighchartPieData(groupedObj) {
    var arr = [];
    var v = 0;
    for (var i in groupedObj) {
        v = groupedObj[i].length ? groupedObj[i].map(c => c.ValorNF || (c.Valor - c.Desconto)).reduce((partial_sum, a) => partial_sum + a) : 0;
        arr.push({ name: i, y: v, valor: numberWithCommas(v) });
    }
    return arr;
}
export function getMonthName(month) {
    switch (month) {
        case 0:
            return 'Jan';
        case 1:
            return 'Fev';
        case 2:
            return 'Mar';
        case 3:
            return 'Abr';
        case 4:
            return 'Mai';
        case 5:
            return 'Jun';
        case 6:
            return 'Jul';
        case 7:
            return 'Ago';
        case 8:
            return 'Set';
        case 9:
            return 'Out';
        case 10:
            return 'Nov';
        case 11:
            return 'Dez';
        default:
    }
}



export const theme = {
    colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
        '#FF9655', '#FFF263', '#6AF9C4'],
    chart: {
        backgroundColor: {
            linearGradient: [0, 0, 500, 500],
            stops: [
                [0, 'rgb(255, 255, 255)'],
                [1, 'rgb(240, 240, 255)']
            ]
        },
    },
    title: {
        style: {
            color: '#000',
            font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
        }
    },
    subtitle: {
        style: {
            color: '#666666',
            font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
        }
    },

    legend: {
        itemStyle: {
            font: '9pt Trebuchet MS, Verdana, sans-serif',
            color: 'black'
        },
        itemHoverStyle: {
            color: 'gray'
        }
    }
};

export function distinct(value, index, self) {
    return self.indexOf(value) === index;
}

export function prepararVeiculo(veiculo) {
  veiculo.AnoMod = veiculo.FIPEAno.name.includes(' ') ? parseInt(veiculo.FIPEAno.name.substring(0, veiculo.FIPEAno.name.indexOf(' '))) : parseInt(veiculo.FIPEAno.name);
  veiculo.Modelo = {
    MarcaId: veiculo.FIPEMarca.id,
    Descricao: veiculo.FIPEModelo.name,
    CodigoFipe: veiculo.FIPEModelo.id,
    CodigoVersao: veiculo.FIPEAno.id,
    Marca: {
      Id: veiculo.FIPEMarca.id,
      Descricao: veiculo.FIPEMarca.name,
      TipoVeiculo: veiculo.TipoVeiculo
    }
  }
}
